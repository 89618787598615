<template>
    <div class="cuerpo">
        <img class="rounded-circle logo-gemgloo fixed-top mt-3 ml-4" src="../assets/images/logo-redondo.png" alt="">
        <span class="span-title h4">Facturacion Electronica</span>
        <div class="container-fluid">
            <div class="row d-flex justify-content-center" style="padding-top:20vh">
                <div class="col-md-4 mt-5 px-5" style="height:500px;">
                    <form @submit.prevent="login" style="height:500px;"
                        class="card border border-gray px-4 pb-3 pt-5">
                        <h1 class="text-center" style="font-size:25px;">Panel Admin</h1>
                        <h2 class="title-neon80 text-cyan-light text-center">Inicio de Sesión</h2>
                        <div class="inputBox mt-5">
                            <input type="email" name="email" required v-model="email" value=""
                            onkeyup="this.setAttribute('value', this.value);"
                            class="pb-1 h5" autocomplete="off">
                            <label>Correo Electronico</label>
                        </div>
                        <div class="inputBox mt-3">
                            <input type="password" name="password" required v-model="password" value=""
                                onkeyup="this.setAttribute('value', this.value);"
                                class="pb-1 h5" autocomplete="off">
                            <label>Contraseña</label>
                            <a href="#"
                            class="text-danger p-2"><u>¿Restablecer Contraseña?</u></a>
                        </div>
                        <b-alert v-model="showAlert" class="pr-0" variant="danger" dismissible>
                            {{validationError}}
                        </b-alert>
                        <div class="row mt-3 mb-3">
                            <div class="col-md-3"></div>
                            <button type="submit" class="col-md-5 btn btn-gradient text-white">Entrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pt-5 text-center text-cyan-light h5" style="color:white;">
                {{getCopyRightYear()}} &copy; Gemgloo Soft SA
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'index',
    data() {
        return {
        email: null,
        password: null,
        showAlert: false,
        validationError: null,
        };
    },
    methods:
    {
        async login() {
            const credenciales = { email: this.email, password: this.password };
            try {
                const response = await axios.post(`${process.env.VUE_APP_BASE_API}/login`, credenciales, { headers: { 'content-type': 'application/json' } });
                if (response.status === 200) {
                    const { access_token } = response.data.data;
                    localStorage.setItem('user-token', access_token);
                    window.location.href = 'dashboard';
                } else {
                    console.log('estado: ', response.status);
                }
            } catch (error) {
                localStorage.removeItem('user-token');
                this.showAlert = true;
                let errors = error.response.data.message;
                let smsValidacion = null;
                if (errors === 1) {
                    smsValidacion = "No existe usuario con el Email dado.";
                }
                if (errors === 0) {
                    smsValidacion = "Contraseña Incorrecta!.";
                }
                if (errors === 2) {
                    smsValidacion = "El usuario no esta activo!.";
                }
                this.validationError = smsValidacion;
            }
        },
        getCopyRightYear() {
            return new Date().getFullYear();
        },
    },
};
</script>

<style scoped>
    .inputBox {
    position: relative;
    }
    .inputBox input {
    width: 100%;
    padding: 0.625rem 0;
    font-size: 1rem;
    color:#50b6ba;
    letter-spacing: 0.062rem;
    margin-bottom: 1.875rem;
    border: none;
    border-bottom: 2px solid #52b4b7;
    outline: none;
    background: transparent;
    }
    .inputBox label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.625rem 0;
    font-size: 1rem;
    color: #50b6ba;
    pointer-events: none;
    transition: 0.5s;
    }
    .inputBox input:focus ~ label,
    .inputBox input:valid ~ label,
    .inputBox input:not([value='']) ~ label {
    top: -1.525rem;
    left: 0;
    color: #50b6ba;
    font-size: 0.75rem;
    }
    .wave
    {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    }
    .on-wave{
    position: fixed;
    z-index: -1;
    top: 35px;
    right: 35px;
    }
    .logo-gemgloo
    {
        width: 95px;
        height: 100px;
    }
    .title-neon80
    {
    font-family:'Neon 80s';
    }
    .text-cyan-light
    {
    color:#50b6ba;
    }
    .border-gray
    {
    border-color: #959595 !important;
    }
    .btn-gradient
    {
    background-image: linear-gradient(to right,#7dd394 0%, #66d69e 51%, #54d8a7 100%);
    border-radius: 15px 15px;
    }
    .cuerpo {
        background: #4FB37B;
    }
    .span-title {
        color: white;
        position: fixed;
        top: 35px;
        right: 35px;
    }
</style>
